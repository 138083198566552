import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipCategorySelectBox from '../../../../../Component/MemberComponent/MemberShipCategorySelectBox.js';
import MemberShipSelectBox from '../../../../../Component/MemberComponent/MemberShipSelectBox.js';
import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput.js';
import PaymentInput from '../../../../../Component/MemberComponent/PaymentInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';
import NormalNumberInput from '../../../../../Component/MemberComponent/NormalNumberInput.js';
import LessonSelectBox from '../../../../../Component/MemberComponent/LessonSelectBox.js';
import MemberShipTotalMaxCountInput from '../../../../../Component/MemberComponent/MemberShipTotalMaxCountInput.js';
import DiscountPaymentInput from '../../../../../Component/MemberComponent/DiscountPaymentInput.js';
import CategorySelectBox from '../../../../../Component/MemberComponent/CategorySelectBox.js';

const MemberShipInfoEditView = forwardRef((props, ref) => {
  const { data, index, userData } = props;

  const history = useHistory();

  const [categoryData, setCategoryData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [memberShipData, setMemberShipData] = useState([]);
  const [divisionMemberShipData, setDivisionMemberShipData] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [validity, setValidity] = useState(0);
  const [validityUnit, setValidityUnit] = useState('day');
  const [totalPrice, setTotalPrice] = useState(0);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [isPartialRefund, setIsPartialRefund] = useState(false);
  const [courseTakeCount, setCourseTakeCount] = useState(0);

  const TotalMaxRef = useRef();
  const LessonSelectRef = useRef();
  const CategorySelectRef = useRef();
  const MemberShipSelectRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const ApprovolCalenderRef = useRef();

  useImperativeHandle(ref, () => ({
    setApprovalDate: (val) => {
      setApprovalDate(val);
    },
    setCardPrice: (val) => {
      CardPaymentRef.current.setValue(val);
    },
    getMemberShipId: () => {
      return MemberShipSelectRef.current.getMemberShipId();
    },
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
    getApprovalDate: () => {
      return approvalDate;
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    },
    getTotalMaxCount: () => {
      // return maxCount;
      return TotalMaxRef.current.getValue();
    },
    getDiscountPrice: () => {
      return DiscountPaymentRef.current.getValue();
    },
    getNotReceivedPrice: () => {
      if (isPartialRefund) return 0;
      else return notReceivedPrice;
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('membership/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setMemberShipData(responseData.data.memberShips);
          setCategoryData(responseData.data.categories);
          // setLessonData(responseData.data.lessonInfos);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  useEffect(() => {
    if (index === -1) return;
    // if (lessonData.length > 0) LessonSelectRef.current.setValue({id: data[index].lessonId, name: data[index].lessonName});
    let validityUnit = 'day';

    if (categoryData.length > 0) CategorySelectRef.current.setValue({ id: data[index].categoryId, name: data[index].categoryName });
    if (memberShipData) {
      let newDataList = [];

      for (let i = 0; i < memberShipData.length; i++) {
        if (memberShipData[i].categoryId === data[index].categoryId) newDataList.push(memberShipData[i]);
        if (memberShipData[i].id === data[index].memberShipId) validityUnit = memberShipData[i].validityUnit;
      }

      MemberShipSelectRef.current.setDefaultVal(newDataList, data[index].memberShipName);
    }


    if (data[index].isPartialRefund) setIsPartialRefund(true);

    setMaxCount(data[index].totalMax);
    TotalMaxRef.current.setValue(data[index].totalMax);
    // setValidity(data[index].validity - 1);
    // console.log(memberShipData);
    // console.log(data[index]);
    setValidity(data[index].validity);
    setValidityUnit(validityUnit);
    setTotalPrice(data[index].totalPrice);
    setNotReceivedPrice(data[index].totalPrice);

    CardPaymentRef.current.setValue(data[index].cardPrice);
    CashPaymentRef.current.setValue(data[index].cashPrice);
    // console.log(data[index]);
    DiscountPaymentRef.current.setValue(data[index].discountPrice);

    let notReceivedPrice = data[index].totalPrice - (data[index].cardPrice + data[index].cashPrice + data[index].discountPrice);

    let notReceivedPriceInDB = data[index].notReceivedPrice;

    // console.log(notReceivedPriceInDB);

    // if (notReceivedPriceInDB)
    if (notReceivedPrice > 0) {
      setNotReceivedPrice(notReceivedPrice);
    }
    else {
      if (notReceivedPriceInDB > 0) setNotReceivedPrice(notReceivedPriceInDB);
      else setNotReceivedPrice(0);
    }
    // console.log(data[index]);

    setStartDate(moment(data[index].startDate).format('YYYY.MM.DD'));
    setEndDate(moment(data[index].endDate).format('YYYY.MM.DD'));
    setApprovalDate(moment(data[index].approvalDate).format('YYYY.MM.DD'));
    setCourseTakeCount(data[index].courseTakeCount);
    // console.log(data[index])
    // console.log(data[index].merchantUid.split('-')[2] === '1');

    // // 링크페이로 결제한 데이터의 경우 //
    // if (data[index].merchantUid.split('-')[2] === '1') {
    //   setOriginalApprovalDate(moment(data[index].approvalDate).format('YYYY.MM.DD'));
    //   setOriginalCardPrice(data[index].cardPrice);
    //   setIsLinkPay(true);
    // }
    
    // MemberShip
  }, [data, index, memberShipData, categoryData])

  // useEffect(() => {
  //   CategorySelectRef.current.setData(categoryData);
  // }, [categoryData])

  // 시작날짜가 변경될때마다 회원권 종료일 자동 계산 ( 유효기간이 0이거나 시작날짜가 정해지지 않으면 무시 )
  useEffect(() => {
    if (startDate === '' || validity === 0) return;

    // setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, 'days').format('YYYY.MM.DD'));

    let division = 'days';

    if (validityUnit === 'year') division = 'year';
    else if (validityUnit === 'month') division = 'month';
    
    setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, division).subtract(1, 'days').format('YYYY.MM.DD'));
  }, [startDate, validity, validityUnit])

  // 회원권 총 일수 결정 //
  function decideMemberShipValidity() {
    if (startDate === endDate) return 1;
    else return moment(endDate).diff(moment(startDate), 'days');
  }

  // 카테고리 선택시 //
  function changeCategorySelectVal(value) {
    var newDataList = [];

    for (var i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].categoryId === value.id) newDataList.push(memberShipData[i]);
    }

    setDivisionMemberShipData(newDataList);
    MemberShipSelectRef.current.setData(newDataList);

    if (newDataList.length === 0) MemberShipSelectRef.current.setValue({ id: -1, name: '선택' });
    else MemberShipSelectRef.current.setValue({ id: 0, name: '선택' });

    TotalMaxRef.current.setValue('');
    setTotalPrice(0);
    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');
  }

  // // 수업 선택시 //
  // function changeLessonSelectVal(value) {

  //   var newDataList = [];

  //   for (var i = 0; i < memberShipData.length; i++) {
  //     if (memberShipData[i].lessonId === value.id) newDataList.push(memberShipData[i]);
  //   }

  //   setDivisionMemberShipData(newDataList);
  //   MemberShipSelectRef.current.setData(newDataList);

  //   if (newDataList.length === 0) MemberShipSelectRef.current.setValue({id: -1, name: '선택'});
  //   else MemberShipSelectRef.current.setValue({id: 0, name: '선택'});

  //   // MemberShipSelectRef.current.setValue({id: 0, name: '선택'});
  //   TotalMaxRef.current.setValue('');
  //   setTotalPrice(0);
  //   CardPaymentRef.current.setValue('');
  //   CashPaymentRef.current.setValue('');
  //   DiscountPaymentRef.current.setValue('');
  // }

  // 회원권 선택시 //
  function changeMemberShipSelectVal(value) {
    setMaxCount(value.totalMax);
    TotalMaxRef.current.setValue(value.totalMax);
    // setValidity(value.validity - 1);
    setValidity(value.validity);
    setValidityUnit(value.validityUnit);

    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');

    setNotReceivedPrice(value.price - (CardPaymentRef.current.getValue() + CashPaymentRef.current.getValue() + DiscountPaymentRef.current.getValue()));
  }

  function changeValue() {
    let card = CardPaymentRef.current.getValue();
    let cash = CashPaymentRef.current.getValue();
    let discount = DiscountPaymentRef.current.getValue();

    // setTotalPrice(parseInt(card) + parseInt(cash));
    setNotReceivedPrice((totalPrice - (card + cash + discount)) > 0 ? (totalPrice - (card + cash + discount)) : 0);
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // 이전 등록 회원권 정보 뷰 정리 //
  function onCalcPrevSalesInfo(className) {
    if (userData === undefined || userData.prevSales === undefined || userData.prevMemberShip === undefined) return '';

    if (className === 'MemberShipName') return userData.prevSales.itemName;
    else if (className === 'Date') return moment(userData.prevUsage.startDate).format('YY.MM.DD') + ' - ' + moment(userData.prevUsage.endDate).format('YY.MM.DD');
    else if (className === 'TotalMax') return userData.prevUsage.totalMax === -1 ? '제한없음' : userData.prevUsage.totalMax;
    else if (className === 'TotalPrice') return (parseInt(userData.prevSales.cashPrice) + parseInt(userData.prevSales.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'CardPrice') return (parseInt(userData.prevSales.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'CashPrice') return (parseInt(userData.prevSales.cashPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'NotReceivedPrice') {
      let total = parseInt(userData.prevMemberShip.price);
      let card = parseInt(userData.prevSales.cardPrice);
      let cash = parseInt(userData.prevSales.cashPrice);
      let discount = parseInt(userData.prevSales.discountPrice);

      if ((total - (card + cash + discount)) > 0) return (total - (card + cash + discount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      else return '0';
    }
    else if (className === 'Approval') return moment(userData.prevSales.approvalDate).format('YY.MM.DD');
    else if (className === 'Validity') {
      if (moment(userData.prevUsage.endDate).diff(moment(), 'day') >= 0) return moment(userData.prevUsage.endDate).diff(moment(), 'day') + '일';
      else return '만료';
    }
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        {/* <MemberShipTitle>회원권 · 수업 정보 수정</MemberShipTitle> */}
        <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>회원권 · 수업 정보 수정</Text>
      </TitleWrapper>
      <InfoBox /*style={{ width: '93.52%' }}*//>
      <TitleWrapper style={{ marginTop: `16px` }}>
        <MemberShipTitle>이전 등록 회원권 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        <RowWrapper>
          <PrevSalesInfoBoxWrapper width={159} marginRight={28}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>회원권</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('MemberShipName')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={127} marginRight={31}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>기간</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('Date')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={55} marginRight={28}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>최대수강</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('TotalMax')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>결제금액</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('TotalPrice')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>카드</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('CardPrice')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>현금</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('CashPrice')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>미수금</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('NotReceivedPrice')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={61} marginRight={28}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>결제일</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('Approval')}</Text>
          </PrevSalesInfoBoxWrapper>
          <PrevSalesInfoBoxWrapper width={55}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>남은 일수</Text>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{onCalcPrevSalesInfo('Validity')}</Text>
          </PrevSalesInfoBoxWrapper>
        </RowWrapper>
      </InfoBox>
      <TitleWrapper style={{ marginTop: `24px` }}>
        <MemberShipTitle>결제 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        <FirstLayerWrapper>
          <ComponentWrapper>
            <ComponentTitle>카테고리</ComponentTitle>
            <CategorySelectBox ref={CategorySelectRef} options={categoryData} changeFn={changeCategorySelectVal} />
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>회원권</ComponentTitle>
            <MemberShipSelectBox ref={MemberShipSelectRef} data={divisionMemberShipData} changeFn={changeMemberShipSelectVal} />
          </ComponentWrapper>
        </FirstLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{ marginRight: `24px` }}>
            <ComponentTitle>회원권 시작일</ComponentTitle>
            <CalenderInput ref={StartCalenderRef} className='StartCalender' value={startDate} setValue={setStartDate} clickFn={CalenderClick} placeholder='시작일' width={280} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `26px` }}>
            <ComponentTitle>회원권 종료일</ComponentTitle>
            <CalenderInput ref={EndCalenderRef} className='EndCalender' value={endDate} setValue={setEndDate} clickFn={CalenderClick} placeholder='종료일' width={280} />
          </ComponentWrapper>
          {/* <ComponentWrapper style={{ marginRight: `38px` }}>
            <ComponentTitle>회원권 총 일수</ComponentTitle>
            <CalcPaymentInputWrapper>
              <InputView color='#CBCBCB' backgroundColor='#FFFFFF'>{decideMemberShipValidity()}</InputView>
              <CalcPaymentUnit>일</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper> */}
          <ComponentWrapper style={{ marginRight: `16px` }}>
            <MemberShipTotalMaxCountInput ref={TotalMaxRef} noLimitCount={maxCount === -1} explanation='최대 수강 횟수' />
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>회원 수강 횟수</ComponentTitle>
            <CalcPaymentInputWrapper>
              <InputView color='#777777' backgroundColor='#DFDFDF'>{courseTakeCount}</InputView>
              <CalcPaymentUnit>회</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
        </SecondLayerWrapper>
        <SecondLayerWrapper style={{ marginTop: `19px` }}>
          <ComponentWrapper style={{ marginRight: `60px` }}>
            <ComponentTitle>결제일</ComponentTitle>
            <CalenderInput ref={ApprovolCalenderRef} value={approvalDate} setValue={setApprovalDate} className='PaymentCalender' clickFn={CalenderClick} placeholder='결제일' width={234} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>회원권 가격</ComponentTitle>
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>카드 결제 금액</ComponentTitle>
            <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>현금 결제 금액</ComponentTitle>
            <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>할인 금액</ComponentTitle>
            <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} />
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>미수금 금액</ComponentTitle>
            <CalcPaymentInputWrapper>
              <NotReceivedPaymentInput>{isPartialRefund ? 0 : notReceivedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</NotReceivedPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default MemberShipInfoEditView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 1020px;
  // height: 593px;

  border-radius: 12px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  margin-top: 28px;
  margin-left: 28px;
`;

const MemberShipTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 60px;
`;

const PrevSalesInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: ${props => props.marginRight}px;

  width: ${props => props.width}px;
  height: 48px;

  overflow: hidden;
  gap: 8px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 2px solid #ECECEC;

  margin-top: 12px;
  margin-left: 28px;

  width: 93.52%;
`;

const FirstLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
  margin-top: 12px;
`;

const SecondLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // gap: 24px;
  margin-top: 14px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 8px;
  margin: 0 0 0 0;

  // width: 252px;
  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const DirectDebitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 168px;
  height: 32px;

  border-radius: 8px;
  background-color: #33C8FF;

  :hover {
    cursor: pointer;
  }
`;

const DirectDebitText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const FixedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const FixedInput = styled.div`
  display: flex;
  flex-direction: column;

  width: 320px;
  height: 30px;

  justify-content: center;
  align-items: flex-start;

  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const FixedUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 8px;

  color: #777777;
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const NotReceivedPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 53px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #FF3131;
  color: ${props => props.color};
`;